import React from 'react';
import { Link } from 'gatsby';

import { ResponsiveImage } from '../media/responsiveImage';
import { ArrowLink } from '../ui/links';
import { useAppContext } from '../../state';

export const CaseHeader = ({ bannerImage, excerpt, projectTags, category, nextCase, prevCase }) => {
  const { setCursorHover } = useAppContext();

  return (
    <div>
      <div className="grid grid-cols-14 gap-y-10 grid-rows-caseBanner">
        <span
          className="w-6 h-20 bg-pull-color row-start-4 row-end-5 self-center relative z-20 col-start-14 -ml-2 lg:-ml-3 lg:h-60 lg:w-8"
          data-scroll-speed="2"
          data-scroll
        />
        <div className="col-start-2 col-end-14 row-start-2 flex justify-between items-center">
          <Link to="/case-studies" className="text-md font-bold">
            Back to cases
          </Link>
          <div className="grid grid-flow-col gap-x-4">
            {prevCase && prevCase.edges.length > 0 && (
              <ArrowLink
                to={`/case-studies/${prevCase.edges[0].node.slug.current}`}
                onMouseEnter={() => setCursorHover(true)}
                onMouseLeave={() => setCursorHover(false)}
              />
            )}
            {nextCase && nextCase.edges.length > 0 && (
              <ArrowLink
                direction="right"
                to={`/case-studies/${nextCase.edges[0].node.slug.current}`}
                onMouseEnter={() => setCursorHover(true)}
                onMouseLeave={() => setCursorHover(false)}
              />
            )}
          </div>
        </div>
        <div className="col-start-2 col-end-14 row-start-3 self-end flex justify-between">
          <h1 className="text-3xl md:text-5xl xl:text-7xl font-medium max-w-2xl">{excerpt}</h1>
          <div className="self-end pt-10 md:pl-10">
            <h4 className="font-bold text-xl mb-2">{category}</h4>
            <h5 className="font-medium text-lg max-w-xs">
              {projectTags.map(
                (item, index) => `${item}${index < projectTags.length - 1 ? ', ' : ' '}`
              )}
            </h5>
          </div>
        </div>

        <div className="col-start-1 col-end-14 row-start-4 row-end-5">
          <ResponsiveImage {...bannerImage} className="w-full" />
        </div>
      </div>
    </div>
  );
};
